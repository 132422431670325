import { createContext, useReducer } from "react";
import { getRequest, postRequest } from "../../services/api";
import authReducer from "./AuthReducer";
import { getLogin } from "./Storage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { token, refreshToken, expiry, loggedIn } = getLogin();

  const initialState = {
    error: null,
    expiry: expiry ? expiry : null,
    token: token ? token : null,
    refreshToken: refreshToken ? refreshToken : null,
    isLoggedIn: loggedIn ? loggedIn : false,
    isLoading: false,
    customer: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const loginUser = async (email, password) => {
    clearErrors();
    setLoading(true);

    const response = await postRequest(
      "customers/login",
      { email, password },
      false
    );

    const status = response.status;
    const data = response.json;

    if (status === 200) {
      const {
        expires_in: expiry,
        access_token: token,
        refresh_token: refreshToken,
      } = data;

      dispatch({
        type: "LOGIN_USER",
        payload: {
          expiry,
          token,
          refreshToken,
        },
      });

      // there is some delay between writing to and reading from localStorage, hence applying some delay to get customer info
      setTimeout(async () => {
        getCustomerInfo();
      }, 100);
    } else {
      dispatch({
        type: "SET_LOGIN_ERROR",
        payload: {
          error: data.message,
        },
      });
    }
  };

  const setLoading = () => {
    dispatch({
      type: "SET_IS_LOADING",
    });
  };

  const clearErrors = () => {
    dispatch({
      type: "CLEAR_LOGIN_ERROR",
    });
  };

  const getCustomerInfo = async () => {
    const isEmployeeUser = await getUserScopes();

    const customer = await getRequest("customers/-");

    dispatch({
      type: "GET_CUSTOMER_DETAILS",
      payload: {
        id: customer.customer_id,
        code: customer.customer_code,
        first_name: customer.first_name,
        last_name: customer.last_name,
        status: customer.status,
        email: customer.email,
        currency_code: customer.currency_code,
        show_stock: customer.show_stock,
        show_price: customer.show_price,
        company_logo: customer.company_logo_url,
        location_id: customer.location,
        is_store_user: isEmployeeUser,
      },
    });

    dispatch({
      type: "SET_LOGGED_IN",
    });
  };

  const getUserScopes = async () => {
    const scopes = await getRequest("scopes");
    return isEmployeeUser(scopes);
  };

  const logoutUser = () => {
    dispatch({
      type: "LOGOUT_USER",
    });
  };

  const isEmployeeUser = (scopes) => {
    const productUpdateAccess = "product.update";
    const productInventoryUpdateAccess = "product.inventory.update";

    return (
      scopes.indexOf(productUpdateAccess) >= 0 &&
      scopes.indexOf(productInventoryUpdateAccess) >= 0
    );
  };

  return (
    <AuthContext.Provider
      value={{
        error: state.error,
        expiry: state.expiry,
        token: state.token,
        refreshToken: state.refreshToken,
        isLoggedIn: state.isLoggedIn,
        isLoading: state.isLoading,
        customerInfo: state.customer,
        loginUser,
        getCustomerInfo,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
