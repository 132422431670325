import React from "react";
import SalesOrdersItem from "./SalesOrdersItem";

function SalesOrdersList({ orders, onDuplicateOrder }) {
  return (
    <table className="table-auto table-compact w-full m-auto mb-4 md:mb-12">
      <thead>
        <tr className="bg-gray-300">
          <th className="p-2 text-xs font-semibold text-left">Creation Date</th>
          <th className="p-2 text-xs font-semibold text-left">Code</th>
          <th className="p-2 text-xs font-semibold text-left">Sub-total</th>
          <th className="p-2 text-xs font-semibold text-left">Tax</th>
          <th className="p-2 text-xs font-semibold text-left">Total</th>
          <th className="p-2 text-xs font-semibold text-left">Notes</th>
          <th className="p-2 text-xs font-semibold text-left">Status</th>
          <th className="p-2 text-xs font-semibold text-left"></th>
          <th className="p-2 text-xs font-semibold text-left"></th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <SalesOrdersItem
            order={order}
            key={order.salesorder_id}
            onDuplicateOrder={onDuplicateOrder}
          />
        ))}
      </tbody>
    </table>
  );
}

export default SalesOrdersList;
