import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";
import ProductContext from "../../context/Product/ProductContext";

function Profile() {
  const { customerInfo, getCustomerInfo, token } = useContext(AuthContext);

  const { showOutOfStockProduct, saveShowOutStockProduct } =
    useContext(ProductContext);

  useEffect(() => {
    if (customerInfo === null) {
      getCustomerInfo(token);
    }
  }, []);

  if (customerInfo === null) {
    return <div></div>;
  }

  const setShowOutStockProducts = (e) => {
    const showOutOfStockProduct = e.target.checked;

    saveShowOutStockProduct(showOutOfStockProduct);
  };

  return (
    <main className="card w-2/3 mx-auto shadow-sm">
      <div className="card-body">
        <h2 className="text-2xl font-bold text-slate-900 my-8">Profile</h2>

        <div className="mt-4">
          <img
            src={customerInfo.company_logo}
            alt="company-logo"
            className="w-20 h-20 rounded-full border-gray-100 border-2"
          />
        </div>
        <div className="mt-4">
          <span className="w-60 inline-block font-semibold">Email</span>
          <span className="inline-block">{customerInfo.email}</span>
        </div>
        <div className="mt-4">
          <span className="w-60 inline-block font-semibold">Fullname</span>
          <span className="inline-block">
            {`${[customerInfo.first_name, customerInfo.last_name].join(" ")}`}
          </span>
        </div>
        <div className="mt-4">
          <span className="w-60 inline-block font-semibold">Code</span>
          <span className="inline-block">{customerInfo.code}</span>
        </div>
        <div className="mt-4">
          <span className="w-60 inline-block font-semibold">Currency</span>
          <span className="inline-block">
            {customerInfo.currency_code ? customerInfo.currency_code : "€"}
          </span>
        </div>

        <div className="mt-4">
          <span className="w-60 inline-block font-semibold">
            Show out-of-stock product
          </span>
          <input
            type="checkbox"
            defaultChecked={showOutOfStockProduct}
            onChange={setShowOutStockProducts}
          />
        </div>

        <div className="mt-16">
          <NavLink to="/users/logout" className="btn btn-neutral btn-sm">
            Logout
          </NavLink>
        </div>
      </div>
    </main>
  );
}

export default Profile;
