import React, { useState, useContext, useEffect } from "react";
import LoadingView from "../../components/Common/LoadingView";
import SalesOrdersList from "../../components/SalesOrders/SalesOrdersList";
import ProductContext from "../../context/Product/ProductContext";
import CartContext from "../../context/Cart/CartContext";
import { getRequest } from "../../services/api";
import AuthContext from "../../context/Auth/AuthContext";

function SalesOrder() {
  const { getSalesOrders, salesOrders, loading } = useContext(ProductContext);
  const { cartProducts, clearCart, addProductsToCart } =
    useContext(CartContext);
  const { customerInfo, getCustomerInfo } = useContext(AuthContext);
  const [salesOrderLoading, setSalesOrderLoading] = useState(false);

  useEffect(() => {
    getSalesOrders();

    if (!customerInfo) {
      getCustomerInfo();
    }
  }, []);

  const duplicateSalesOrderHandler = async (id) => {
    if (salesOrderLoading) {
      alert("Already duplicating a sales order.");
      return;
    }

    if (cartProducts?.length > 0) {
      const shouldClear = window.confirm(
        "You already have items in cart. Do you want to clear your cart before duplicating?"
      );

      if (shouldClear) {
        clearCart();
      } else {
        return;
      }
    }

    setSalesOrderLoading(true);
    const order = await getRequest(`sales-orders/${id}`);

    const _cartItems = order.details.map((d) => {
      return {
        product: { ...d.product, qty_per_box: d.qty_per_box },
        quantity: d.ordered_qty,
        boxQuantity: d.ordered_box_qty,
      };
    });

    addProductsToCart(_cartItems);

    setSalesOrderLoading(false);
  };

  return (
    <div>
      {(loading || salesOrderLoading) && <LoadingView />}

      <h3 className="text-3xl mb-8 font-bold">Sales Orders</h3>

      {salesOrders.length > 0 ? (
        <SalesOrdersList
          orders={salesOrders}
          onDuplicateOrder={duplicateSalesOrderHandler}
        />
      ) : !loading ? (
        <div>No sales oder found</div>
      ) : null}
    </div>
  );
}

export default SalesOrder;
