import React from "react";

function PaginationButton({ active, disabled, onClick, children }) {
  return (
    <button
      className={`btn btn-sm ${
        active ? "btn-accent" : "btn-primary btn-outline"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default PaginationButton;
