import React, { useState, useEffect } from "react";

function NewArrivalProductListItem({ product }) {
  return (
    <tr className="py-4 hover:bg-gray-100">
      <td className="p-2 py-4 border-b text-xs xl:text-sm">
        {product.product_code}
      </td>
      <td className="p-2 border-b text-xs xl:text-sm">
        {product.product_name}
      </td>
      <td className="p-2 border-b text-center text-xs xl:text-sm">
        {product.qty_per_box ? product.qty_per_box : "-"}
      </td>
    </tr>
  );
}

export default NewArrivalProductListItem;
