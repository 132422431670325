export const saveCartItems = (cart) => {
  removeCartItems();
  localStorage.setItem("cart", JSON.stringify(cart));
};

export const getCartItems = () => {
  let cart = localStorage.getItem("cart");
  return cart ? JSON.parse(cart) : [];
};

export const removeCartItems = () => {
  localStorage.removeItem("cart");
};

export const saveCartSummary = (summary) => {
  removeCartSummary();
  localStorage.setItem("cart_summary", JSON.stringify(summary));
};

export const getCartSummary = () => {
  let summary = localStorage.getItem("cart_summary");
  return summary ? JSON.parse(summary) : {};
};

export const removeCartSummary = () => {
  localStorage.removeItem("cart_summary");
};

export const saveCartBoxSummary = (summary) => {
  removeCartBoxSummary();
  localStorage.setItem("cart_box_summary", JSON.stringify(summary));
};

export const getCartBoxSummary = () => {
  let summary = localStorage.getItem("cart_box_summary");
  return summary ? JSON.parse(summary) : {};
};

export const removeCartBoxSummary = () => {
  localStorage.removeItem("cart_box_summary");
};
