import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, patchRequest } from "../../services/api";
import AuthContext from "../../context/Auth/AuthContext";

export default function UpdateProduct() {
  const [storageBin, setStorageBin] = useState("");
  const [qtyPerBox, setQtyPerBox] = useState(0);
  const [boxBarcode, setBoxBarcode] = useState("");
  const [barcode, setBarcode] = useState("");
  const [barcode01, setBarcode01] = useState("");
  const [barcode02, setBarcode02] = useState("");
  const [product, setProduct] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [message, setMessage] = useState("");

  const { customerInfo: customer } = useContext(AuthContext);
  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();

    updateProduct();
  };

  const { productId } = useParams();

  useEffect(() => {
    if (customer?.is_store_user !== true) {
      navigate(-1);
    } else {
      if (productId) {
        getProduct(productId);
      }
    }
  }, [customer, navigate, productId]);

  const getProduct = async (id) => {
    setLoading(true);

    const product = await getRequest(`products/${id}`);

    setLoading(false);

    if (product) {
      setBarcode(product.barcode ?? "");
      setBarcode01(product.barcode_01 ?? "");
      setBarcode01(product.barcode_02 ?? "");
      setStorageBin(product.storage_bin ?? "");
      setQtyPerBox(product.qty_per_box ?? 0);
      setBoxBarcode(product.box_barcode ?? "");
      setProduct(product);
    }
  };

  const updateProduct = async () => {
    if (!productId) {
      return;
    }

    setMessage("");
    setError([]);

    if (storageBin === "") {
      setError((prev) => {
        return [...prev, "Storage bin cannot be empty."];
      });
    }

    if (!Number.isInteger(parseInt(qtyPerBox)) || +qtyPerBox <= 0) {
      setError((prev) => {
        return [...prev, "Quantity per box should be a positive number."];
      });
    }

    if (boxBarcode === "") {
      setError((prev) => {
        return [...prev, "Box barcode cannot be empty."];
      });
    }

    if (barcode === "") {
      setError((prev) => {
        return [...prev, "Barcode cannot be empty."];
      });
    }

    var params = {};
    if (error.length === 0) {
      setIsSubmitting(true);
      params = {
        ...params,
        storage_bin: storageBin,
        qty_per_box: qtyPerBox,
        box_barcode: boxBarcode,
        barcode: barcode,
      };

      if (barcode01 !== "") {
        params = { ...params, barcode_01: barcode01 };
      }

      if (barcode02 !== "") {
        params = { ...params, barcode_02: barcode02 };
      }

      const response = await patchRequest(`products/${productId}`, params);
      const httpStatus = response.status;
      const { message } = response.json;

      var success = false;
      if (httpStatus >= 200 && httpStatus < 300) {
        success = true;
      }

      if (!success) {
        setMessage("");
        setError((_) => [message]);
      } else {
        setError([]);
        setMessage(message);
      }
      setIsSubmitting(false);
      console.log(message);
    }
  };

  return (
    <>
      <div className="relative flex flex-1 flex-col pt-12 pb-16">
        <div className="max-w-md w-full space-y-8">
          <div className="w-full max-w-sm mb-8">
            <h2 className="mt-8  mb-2 text-xl text-gray-600">
              Update Product {product?.product_name}
            </h2>
            <h5>Product Code {product?.product_code}</h5>
          </div>

          <div className="w-full max-w-sm">
            <h4 className="mt-8 mb-8 text-xl text-gray-600"></h4>
          </div>

          {loading ? (
            <div>
              <div className="inline-block align-middle">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
              <div className="inline-block align-middle">Please Wait...</div>
            </div>
          ) : (
            <form method="POST" onSubmit={submitHandler}>
              {error.length > 0 && (
                <div className="text-xs text-red-400 bg-red-100 p-2 rounded-lg mb-4">
                  <ul>
                    {error.map((err, idx) => (
                      <li key={idx}>{err}</li>
                    ))}
                  </ul>
                </div>
              )}

              {message !== "" && (
                <div className="text-xs text-blue-500 bg-blue-100 p-2 rounded-lg mb-4 py-4 px-4">
                  {message}
                  <button
                    type="button"
                    className="float-right"
                    onClick={() => setMessage("")}
                  >
                    X
                  </button>
                </div>
              )}

              <div className="form-control mb-6">
                <label htmlFor="storage-bin" className="label">
                  Storage Bin
                </label>
                <input
                  id="storage-bin"
                  name="storage-bin"
                  type="text"
                  className="input input-bordered rounded-lg"
                  placeholder="Storage Bin"
                  value={storageBin}
                  onChange={(e) => setStorageBin(e.target.value)}
                />
              </div>

              <div className="form-control mb-6">
                <label htmlFor="qty-per-box" className="label">
                  Qty/Box
                </label>
                <input
                  id="qty-per-box"
                  name="qty-per-box"
                  type="text"
                  className="input input-bordered rounded-lg"
                  placeholder="Quantity in Box"
                  value={qtyPerBox ?? ""}
                  onChange={(e) => setQtyPerBox(e.target.value)}
                />
              </div>

              <div className="form-control mb-6">
                <label htmlFor="box-barcode" className="label">
                  Box Barcode
                </label>
                <input
                  id="box-barcode"
                  name="box-barcode"
                  type="text"
                  className="input input-bordered rounded-lg"
                  placeholder="Box Barcode"
                  value={boxBarcode}
                  onChange={(e) => setBoxBarcode(e.target.value)}
                />
              </div>

              <div className="form-control mb-6">
                <label htmlFor="barcode" className="label">
                  Barcode
                </label>
                <input
                  id="barcode"
                  name="barcode"
                  type="text"
                  className="input input-bordered rounded-lg"
                  placeholder="Barcode"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                />
              </div>

              <div className="form-control mb-6">
                <label htmlFor="barcode01" className="label">
                  Barcode 01
                </label>
                <input
                  id="barcode01"
                  name="barcode01"
                  type="text"
                  className="input input-bordered rounded-lg"
                  placeholder="Barcode 01"
                  value={barcode01}
                  onChange={(e) => setBarcode01(e.target.value)}
                />
              </div>

              <div className="form-control mb-6">
                <label htmlFor="barcode02" className="label">
                  Barcode 02
                </label>
                <input
                  id="barcode02"
                  name="barcode02"
                  type="text"
                  className="input input-bordered rounded-lg"
                  placeholder="Barcode 02"
                  value={barcode02}
                  onChange={(e) => setBarcode02(e.target.value)}
                />
              </div>

              <div className="mb-6 mt-4">
                <button
                  type="submit"
                  className={`btn w-full rounded-lg ${
                    isSubmitting ? "loading btn-disabled" : ""
                  }`}
                  disabled={isSubmitting}
                >
                  Update
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
