const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM_TO_CART":
      return {
        ...state,
        cartProducts: action.payload.items,
        cartSummary: action.payload.itemsSummary,
        cartBoxSummary: action.payload.itemsBoxSummary,
      };
    case "CLEAR_CART":
      return {
        ...state,
        cartProducts: [],
        cartSummary: {},
        cartBoxSummary: {},
        salesOrderSuccess: null,
        //salesOrderMessage: null,
      };
    case "CREATE_SALES_ORDER":
      return {
        ...state,
        salesOrderSuccess: action.payload.success,
        salesOrderMessage: action.payload.message,
        loading: false,
      };
    case "REMOVE_CART_ITEM":
      return {
        ...state,
        cartProducts: action.payload.products,
        cartSummary: action.payload.summary,
        cartBoxSummary: action.payload.boxSummary,
      };
    case "CLEAR_CART_MESSAGE":
      return {
        ...state,
        salesOrderMessage: null,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default cartReducer;
