import React, { useContext } from "react";
import { TrashIcon } from "@heroicons/react/solid";
import CartContext from "../../context/Cart/CartContext";

function CartListItem({ item, quantity, boxQuantity, showPrice }) {
  const { addProductToCart, removeProduct } = useContext(CartContext);

  const handleQuantityChange = (e) => {
    let newQuantity = parseInt(e.target.value);
    addProductToCart(item, newQuantity);
  };

  const handleDelete = () => {
    if (window.confirm(`Remove ${item.product_name} from cart?`) === true) {
      removeProduct(item);
    }
  };

  return (
    <tr>
      <td className="p-2 border-b text-xs xl:text-sm">{item.product_code}</td>
      <td className="p-2 border-b text-xs xl:text-sm">{item.product_name}</td>
      <td className="p-2 border-b text-xs xl:text-sm text-center w-20">
        <input
          type="number"
          value={boxQuantity ? boxQuantity : ""}
          className="w-20 rounded-md border-gray-100 text-sm"
          onChange={handleQuantityChange}
        />
      </td>
      <td className="p-2 border-b text-xs xl:text-sm">
        {item.qty_per_box ? item.qty_per_box : "-"}
      </td>
      <td className="p-2 border-b text-xs xl:text-sm text-center w-20">
        <input
          type="number"
          value={quantity}
          className="w-20 rounded-md border-gray-100 text-sm"
          onChange={handleQuantityChange}
        />
      </td>
      {showPrice && (
        <>
          <td className="p-2 border-b text-xs xl:text-sm text-center">
            € {parseFloat(item.sales_price).toFixed(2)}
          </td>
          <td className="p-2 border-b text-xs xl:text-sm text-center">
            € {parseFloat(item.sales_price * quantity).toFixed(2)}
          </td>
        </>
      )}
      <td className="p-2 border-b text-xs xl:text-sm w-6">
        <button className="btn btn-ghost btn-sm" onClick={handleDelete}>
          <TrashIcon className="h-6 w-6" />
        </button>
      </td>
    </tr>
  );
}

export default CartListItem;
