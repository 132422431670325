import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { CameraIcon } from "@heroicons/react/solid";
import LoadingView from "../../components/Common/LoadingView";
import BarcodeProductSearch from "../../components/Products/BarcodeProductSearch";
import Pagination from "../../components/Products/Pagination";
import ProductCategoryList from "../../components/Products/ProductCategoryList";
import ProductList from "../../components/Products/ProductList";
import ProductSearch from "../../components/Products/ProductSearch";
import CartContext from "../../context/Cart/CartContext";
import ProductContext from "../../context/Product/ProductContext";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { AuthorizationError } from "../../services/api";
import UpdateStockForm from "../../components/Products/UpdateStockForm";
import AuthContext from "../../context/Auth/AuthContext";

function Dashboard() {
  //const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [barcode, setBarcode] = useState("");
  const [scannerActive, setScannerActive] = useState(false);
  const [shouldUpdateStock, setShouldUpdateStock] = useState(false);
  const [stockProduct, setStockProduct] = useState();

  const navigate = useNavigate();

  const {
    categories,
    currentProducts,
    barcodeProduct,
    currentPage,
    lastPage,
    loading,
    fetchProducts,
    clearProducts,
    fetchCategories,
    fetchBarcodeProduct,
    clearBarcodeProduct,
  } = useContext(ProductContext);

  const { addProductToCart, cartSummary } = useContext(CartContext);
  const { customerInfo, getCustomerInfo, token } = useContext(AuthContext);

  {
    /* Get user details and scope info */
  }
  useEffect(() => {
    if (
      token !== "" &&
      (customerInfo === null || customerInfo?.is_store_user === undefined)
    ) {
      getCustomerInfo(token);
    }
  }, []);

  useEffect(() => {
    if (barcodeProduct) {
      const existingQty = cartSummary[`${barcodeProduct.product_id}`]
        ? cartSummary[`${barcodeProduct.product_id}`]
        : 0;
      const updatedQty = existingQty + 1;
      addProductToCart(barcodeProduct, updatedQty);
    }
  }, [barcodeProduct]);

  useEffect(() => {
    clearBarcodeProduct();

    fetchCategories().catch((error) => {
      if (error instanceof AuthorizationError) {
        navigate("/users/logout");
      } else {
        console.log(error.message);
      }
    });

    fetchProducts().catch((error) => {
      if (error instanceof AuthorizationError) {
        navigate("/users/logout");
      } else {
        console.log(error.message);
      }
    });
  }, []);

  const handlePagination = (_page) => {
    fetchProducts(_page, searchText);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    clearProducts();

    setBarcode("");
    clearBarcodeProduct();

    fetchProducts(1, text);
  };

  const handleSearchClear = () => {
    setSearchText("");

    clearProducts();

    fetchProducts(1, "");
  };

  const handleBarcodeSearch = (code) => {
    setBarcode(code);
    setSearchText("");
    fetchBarcodeProduct(code);
  };

  const handleBarcodeClear = () => {
    clearBarcodeProduct();
    setBarcode("");
  };

  const handleScannerActiveState = () => {
    setScannerActive((prev) => !prev);
  };

  const onUpdateStock = (product) => {
    setStockProduct(product);
    setShouldUpdateStock(true);
  };

  return (
    <div className="flex flex-col md:flex-row">
      {loading && <LoadingView />}

      {categories.length > 0 && <ProductCategoryList categories={categories} />}

      <div className="grow">
        {scannerActive && (
          <div>
            <BarcodeScannerComponent
              width={500}
              height={500}
              stopStream={scannerActive}
              onUpdate={(err, result) => {
                if (result) {
                  setScannerActive(false);
                  handleBarcodeSearch(result.text);
                }
              }}
            />
          </div>
        )}

        <section className="lg:flex lg:flex-row lg:gap-16">
          <div className="lg:basis-1/2 mb-4 mx-2 sm:mx-0">
            <ProductSearch
              onSearch={handleSearch}
              onClear={handleSearchClear}
              defaultText={searchText}
            />
          </div>

          <div className="lg:basis-1/2 mb-4 mx-2 sm:mx-0">
            <BarcodeProductSearch
              onSearch={handleBarcodeSearch}
              onClear={handleBarcodeClear}
              defaultText={barcode}
            />

            <div className="text-right mt-4">
              <div>
                <span className="text-xs text-gray-300 mr-4">OR</span>
                <button
                  className="btn rounded-lg"
                  onClick={handleScannerActiveState}
                  title="click to scan barcode"
                >
                  <CameraIcon className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </section>

        {barcodeProduct && (
          <ProductList
            products={[barcodeProduct]}
            onUpdateStock={onUpdateStock}
          />
        )}

        {currentProducts.length > 0 && barcodeProduct === undefined ? (
          <>
            <ProductList
              products={currentProducts}
              onUpdateStock={onUpdateStock}
            />

            <div className="flex flex-row-reverse mt-8">
              <Pagination
                active={currentPage}
                pages={lastPage}
                onPaginate={handlePagination}
              />
            </div>
          </>
        ) : null}

        {shouldUpdateStock && (
          <UpdateStockForm
            product={stockProduct}
            customer={customerInfo}
            onDismissForm={() => setShouldUpdateStock(false)}
          />
        )}
      </div>
    </div>
  );
}

export default Dashboard;
