const newProductReducer = (state, action) => {
  switch (action.type) {
    case "GET_NEW_PRODUCTS":
      const _allProducts = [...state.allProducts];
      _allProducts[action.payload.currentPage] = action.payload.currentProducts;

      return {
        ...state,
        allProducts: _allProducts,
        currentProducts: action.payload.currentProducts,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        loading: false,
      };
    case "LOAD_NEW_PRODUCTS":
      return {
        ...state,
        currentProducts: action.payload.currentProducts,
        currentPage: action.payload.currentPage,
        loading: false,
      };
    case "CLEAR_NEW_PRODUCTS":
      return {
        ...state,
        allProducts: [],
        currentProducts: [],
        currentPage: 0,
        lastPage: 0,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default newProductReducer;
