import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import ProductList from "../../components/Products/ProductList";
import Pagination from "../../components/Products/Pagination";
import ProductContext from "../../context/Product/ProductContext";
import ProductCategoryList from "../../components/Products/ProductCategoryList";
import { getToken } from "../../context/Auth/Storage";
import LoadingView from "../../components/Common/LoadingView";
import { getRequest } from "../../services/api";

function CategoryProduct() {
  const { categoryId } = useParams();

  const { categories, fetchCategories } = useContext(ProductContext);

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(() => {
    if (categories.length === 0) {
      fetchCategories();
    }
  }, []);

  useEffect(() => {
    fetchCategoryProducts(1);
  }, [categoryId]);

  useEffect(() => {
    setCurrentCategory(categories.filter((c) => c.id === +categoryId)[0]);
  }, [categories, categoryId]);

  const fetchCategoryProducts = async (page) => {
    const token = getToken();
    setLoading(true);

    const { data, current_page, last_page } = await getRequest(
      `products?limit=25&page=${page}&filter[product_family_id]=${categoryId}`
    );

    setProducts(data);
    setCurrentPage(current_page);
    setLastPage(last_page);
    setLoading(false);
  };

  const handlePagination = (_page) => {
    fetchCategoryProducts(_page);
  };

  return (
    <section className="flex flex-row">
      {loading && <LoadingView />}

      {categories.length > 0 && (
        <ProductCategoryList
          categories={categories}
          currentCategory={currentCategory}
        />
      )}

      <div className="grow">
        {products.length > 0 ? (
          <>
            <ProductList products={products} />

            <div className="flex flex-row-reverse mt-8">
              <Pagination
                active={currentPage}
                pages={lastPage}
                onPaginate={handlePagination}
              />
            </div>
          </>
        ) : null}

        {!loading && products.length == 0 ? (
          <p className="text-xs text-red-400 bg-red-100 p-2 rounded-lg">
            No product found for{" "}
            {currentCategory ? currentCategory.desc : "the category"}
          </p>
        ) : null}
      </div>
    </section>
  );
}

export default CategoryProduct;
