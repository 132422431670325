import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/Auth/AuthContext";
import CartContext from "../../context/Cart/CartContext";
import ProductListItem from "./ProductListItem";

function ProductList({ products, onUpdateStock }) {
  const { addProductToCart, cartSummary, cartBoxSummary } =
    useContext(CartContext);
  const { customerInfo: customer, getCustomerInfo } = useContext(AuthContext);

  useEffect(() => {
    if (!customer) {
      getCustomerInfo();
    }
  }, []);

  const addProductHandler = (product, quantity, boxQuantity) => {
    addProductToCart(product, quantity, boxQuantity);
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto my-4 w-full">
        <thead>
          <tr className="bg-gray-300">
            <th className="p-2 text-xs font-semibold text-left">Code</th>
            <th className="p-2 text-xs font-semibold text-left">Product</th>
            {customer?.show_price && (
              <th className="p-2 text-xs font-semibold text-right">
                Unit Price
              </th>
            )}
            {customer?.show_stock && (
              <th className="p-2 text-xs font-semibold text-right">Stock</th>
            )}
            {customer?.is_store_user !== true && (
              <>
                <th className="p-2 text-xs font-semibold text-center w-20">
                  Qty/Box
                </th>

                <th className="p-2 text-xs font-semibold text-center w-20">
                  Box Nos.
                </th>

                <th className="p-2 text-xs font-semibold text-center w-20">
                  Qty
                </th>
              </>
            )}
            {customer?.is_store_user === true && (
              <th className="p-2 text-xs font-semibold text-center w-20"></th>
            )}
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <ProductListItem
              key={product.product_id}
              product={product}
              selectedQuantity={cartSummary[`${product.product_id}`]}
              selectedBoxQuantity={cartBoxSummary[`${product.product_id}`]}
              onAdded={addProductHandler}
              showPrice={customer?.show_price}
              showStock={customer?.show_stock}
              isStoreUser={customer?.is_store_user}
              onUpdateStock={() => onUpdateStock(product)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductList;
