import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/solid";
import { calculateTotalStock } from "../../helpers/Calculation";

function ProductListItem({
  product,
  selectedQuantity,
  selectedBoxQuantity,
  onAdded,
  showPrice,
  showStock,
  isStoreUser,
  onUpdateStock,
}) {
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [boxOrderQuantity, setBoxOrderQuantity] = useState(0);

  const stockQty = calculateTotalStock(product.stocks);

  useEffect(() => {
    if (selectedQuantity) {
      setOrderQuantity(selectedQuantity);
    }
    if (selectedBoxQuantity) {
      setBoxOrderQuantity(selectedBoxQuantity);
    }
  }, [selectedQuantity, selectedBoxQuantity]);

  const handleQuantityUpdate = (e) => {
    const qty = parseInt(e.target.value);

    if (qty >= 0) {
      setOrderQuantity(qty);
      setBoxOrderQuantity(0);
      onAdded(product, qty, null);
    }
  };

  const handleBoxQuantityUpdate = (e) => {
    if (!product.qty_per_box) {
      setBoxOrderQuantity(0);
      return;
    }

    const qtyPerBox = parseInt(product.qty_per_box);

    if (qtyPerBox <= 0) {
      setBoxOrderQuantity(0);
      return;
    }

    const qty = parseInt(e.target.value);

    if (qty >= 0) {
      setBoxOrderQuantity(qty);

      let totalQuantity = qtyPerBox * qty;

      setOrderQuantity(totalQuantity);

      onAdded(product, totalQuantity, qty);
    }
  };

  return (
    <tr className={`${orderQuantity > 0 ? "bg-primary" : "hover:bg-gray-100"}`}>
      <td className="p-2 border-b text-xs xl:text-sm">
        {product.product_code}
      </td>
      <td className="p-2 border-b text-xs xl:text-sm">
        {isStoreUser ? (
          <NavLink
            to={`/users/products/update/${product.product_id}`}
            className="underline inline-block py-2"
          >
            {product.product_name}
          </NavLink>
        ) : (
          <>{product.product_name}</>
        )}
      </td>

      {showPrice && (
        <td className="p-2 border-b text-right text-xs xl:text-sm">
          {product.sales_price ? `€${product.sales_price.toFixed(2)}` : ""}
        </td>
      )}
      {showStock && (
        <td className="p-2 border-b text-center text-xs xl:text-sm">
          {stockQty}
        </td>
      )}
      {!isStoreUser && (
        <>
          <td className="p-2 border-b text-center text-xs xl:text-sm">
            {product.qty_per_box}
          </td>
          <td className="p-2 border-b text-center text-xs xl:text-sm">
            <input
              type="number"
              min="0"
              value={`${boxOrderQuantity === 0 ? "" : boxOrderQuantity}`}
              className={`input input-bordered w-20 input-xs xl:input-sm text-xs xl:text-sm ${
                boxOrderQuantity === 0 ? "bg-gray-100 border-gray-200" : ""
              }`}
              onChange={handleBoxQuantityUpdate}
            />
          </td>
          <td className="p-2 border-b">
            <input
              type="number"
              min="0"
              value={`${orderQuantity === 0 ? "" : orderQuantity}`}
              className={`input input-bordered w-20 input-xs xl:input-sm text-xs xl:text-sm ${
                orderQuantity === 0 ? "bg-gray-100 border-gray-200" : ""
              }`}
              onChange={handleQuantityUpdate}
            />
          </td>
        </>
      )}
      {/* Display Update Stock Action Button */}
      {isStoreUser && (
        <td className="p-2 border-b text-right text-xs xl:text-sm w-32">
          <button
            className="btn btn-sm rounded-full"
            title="click to update inventory"
            onClick={() => onUpdateStock(product)}
          >
            <div className="flex flex-row">
              <PencilIcon width={18} height={18} />
              <span className="text-xs text-gray-300 mx-2"> Stock</span>
            </div>
          </button>
        </td>
      )}
    </tr>
  );
}

export default ProductListItem;
