import React from "react";
import CartListItem from "./CartListItem";

function CartList({ items, showPrice }) {
  return (
    <table className="table-auto table-compact w-4/5 m-auto mb-4 md:mb-12">
      <thead>
        <tr className="bg-gray-300">
          <th className="p-2 text-xs font-semibold text-left w-10">Code</th>
          <th className="p-2 text-xs font-semibold text-left">Item</th>
          <th className="p-2 text-xs font-semibold w-20 text-center">
            Box Qty
          </th>
          <th className="p-2 text-xs font-semibold w-20 text-center">
            Qty/Box
          </th>
          <th className="p-2 text-xs font-semibold w-20 text-center">Qty</th>
          {showPrice && (
            <>
              <th className="p-2 text-xs font-semibold w-20 text-center">
                Price
              </th>

              <th className="p-2 text-xs font-semibold w-20 text-center">
                Total Price
              </th>
            </>
          )}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {items.map((it) => (
          <CartListItem
            key={it.product.product_id}
            item={it.product}
            quantity={it.quantity}
            boxQuantity={it.boxQuantity}
            showPrice={showPrice}
          />
        ))}
      </tbody>
    </table>
  );
}

export default CartList;
