import { Outlet } from "react-router-dom";

function App() {
  return (
    <main className="min-h-full relative flex flex-1 flex-col overflow-hidden py-8 px-4 sm:px-6 lg:px-8">
      <Outlet />
    </main>
  );
}

export default App;
