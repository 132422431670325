import { clearLogin, saveLogin, saveUser } from "./Storage";

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      saveLogin(
        action.payload.token,
        action.payload.refreshToken,
        action.payload.expiry,
        true
      );

      return {
        ...state,
        error: null,
        expiry: action.payload.expiry,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_FINISH_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    case "SET_LOGIN_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case "CLEAR_LOGIN_ERROR":
      return {
        ...state,
        error: null,
      };
    case "LOGOUT_USER": {
      clearLogin();

      return {
        ...state,
        expiry: null,
        token: null,
        refreshToken: null,
        isLoggedIn: false,
      };
    }
    case "GET_CUSTOMER_DETAILS":
      saveUser(
        action.payload.last_name,
        action.payload.email,
        action.payload.is_store_user
      );
      return {
        ...state,
        customer: action.payload,
      };
    case "SET_LOGGED_IN":
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
