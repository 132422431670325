import React, { useContext, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  ShoppingCartIcon,
  MenuIcon,
  XIcon,
  LogoutIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import CartContext from "../../context/Cart/CartContext";
import { getUser } from "../../context/Auth/Storage";
import logo from "../../logo.png";

const navigation = [
  { name: "Dashboard", href: "/users" },
  { name: "Sales Orders", href: "/users/sales-orders" },
  { name: "New Arrivals", href: "/users/new-arrivals" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Navigation() {
  const user = getUser();

  const { cartProducts } = useContext(CartContext);

  const navigate = useNavigate();

  const location = useLocation();

  const showCartHandler = () => {
    navigate("/users/cart");
  };

  return (
    <>
      <Disclosure
        as="nav"
        className="bg-gray-800 fixed top-0 left-0 right-0 z-50"
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link to="/users">
                      <img
                        className="h-20 w-24 rounded-full text-xs"
                        src={logo}
                        alt="Easerp Orders"
                      />
                    </Link>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.href === location.pathname
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={
                            item.href === location.pathname ? "page" : undefined
                          }
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <button
                      type="button"
                      className="indicator bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white"
                      onClick={showCartHandler}
                    >
                      <span className="sr-only">View cart</span>
                      <ShoppingCartIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                      {cartProducts?.length > 0 && (
                        <span className="indicator-item badge badge-secondary">
                          {cartProducts.length}
                        </span>
                      )}
                    </button>

                    {/* Profile dropdown */}
                    <Menu
                      as="div"
                      className="ml-8 relative dropdown dropdown-end"
                    >
                      <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm">
                        <span className="sr-only">Open user menu</span>
                        {user.imageUrl ? (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.imageUrl}
                            alt=""
                          />
                        ) : (
                          <UserCircleIcon className="w-6 h-6 text-gray-400 hover:text-white" />
                        )}
                      </Menu.Button>

                      <Menu.Items
                        as="ul"
                        className="dropdown-content menu shadow-lg rounded-md mt-3 bg-gray-900"
                      >
                        <Menu.Item as="li" tabIndex={"1"}>
                          {({ active }) => (
                            <NavLink
                              to="profile"
                              className={`${
                                active && "bg-gray-700"
                              } px-12 text-gray-300`}
                            >
                              Profile
                            </NavLink>
                          )}
                        </Menu.Item>

                        <Menu.Item as="li" tabIndex={"2"}>
                          {({ active }) => (
                            <NavLink
                              to="logout"
                              className={`${
                                active && "bg-gray-700"
                              } px-12 text-gray-300`}
                            >
                              Logout
                            </NavLink>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.href === location.pathname
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={
                      item.href === location.pathname ? "page" : undefined
                    }
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    {user.imageUrl ? (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    ) : (
                      <UserCircleIcon className="w-8 h-8 text-gray-400" />
                    )}
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium leading-none text-gray-400">
                      {user.email}
                    </div>
                    <div className="text-xs font-medium leading-none mt-2 text-gray-500 hover:text-white">
                      <NavLink to="profile">View Profile</NavLink>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="indicator ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white"
                    onClick={showCartHandler}
                  >
                    <span className="sr-only">View Cart</span>
                    <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />

                    {cartProducts?.length > 0 && (
                      <span className="indicator-item badge badge-secondary">
                        {cartProducts.length}
                      </span>
                    )}
                  </button>
                  <NavLink
                    to="logout"
                    type="button"
                    className="indicator ml-3 bg-gray-800 rounded-full text-gray-400 hover:text-white"
                  >
                    <LogoutIcon className="h-6 w-6" />
                  </NavLink>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}

export default Navigation;
