import React from "react";
import {
  InformationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";

function AlertView({ message, category }) {
  var alertType = "alert-info";
  var icon = <InformationCircleIcon className="w-6 h-6" />;
  if (category === "error") {
    icon = <XCircleIcon className="w-6 h-6" />;
    alertType = "alert-error";
  } else if (category === "success") {
    icon = <CheckCircleIcon className="w-6 h-6" />;
    alertType = "alert-success";
  }

  return (
    <div
      className={`alert ${alertType} shadow-lg fixed top-12 right-0 w-2/5 mr-4`}
    >
      <div>
        {icon}
        <span>{message}</span>
      </div>
    </div>
  );
}

export default AlertView;
