import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingView from "../../components/Common/LoadingView";
import { getRequest } from "../../services/api";

function SalesOrderDetails() {
  const { salesOrderId } = useParams();

  const [salesOrder, setSalesOrder] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (salesOrderId) {
      getSalesOrder(salesOrderId);
    }
  }, [salesOrderId]);

  const getSalesOrder = async (id) => {
    const response = await getRequest(`sales-orders/${id}`);

    setSalesOrder(response);
    setLoading(false);
  };

  var salesOrderEl = null;
  if (salesOrder) {
    const { customer, details } = salesOrder;

    salesOrderEl = (
      <div className="card w-full lg:w-2/3 bg-base-100 shadow-xl text-md mx-auto">
        <div className="card-body w-full">
          <div className="text-lg font-semibold bg-gray-100 mb-6 p-4">
            Sales Order {salesOrder.salesorder_code}
          </div>
          <div className="flex flex-row gap-4 mb-4">
            <h4 className="font-bold">Customer</h4>
            <div>
              {customer?.last_name}
              <br />
              <span className="text-xs">{customer?.email}</span>
            </div>
          </div>

          <table className="table-compact table-auto w-full mb-4">
            <thead>
              <tr className="bg-gray-300">
                <th className="text-xs">Product</th>
                <th className="text-xs">Qty/Box</th>
                <th className="text-xs">Ordered Box Qty</th>
                <th className="text-xs">Quantity</th>
                <th className="text-xs">Unit Price</th>
              </tr>
            </thead>
            <tbody>
              {details.map((detail) => (
                <tr
                  className=""
                  key={`${detail.product_id}-${detail.salesorder_detail_id}`}
                >
                  <td className="border-b text-sm">
                    {detail.product?.product_name}
                  </td>
                  <td className="border-b text-sm text-center">
                    {detail.qty_per_box ? detail.qty_per_box : "-"}
                  </td>
                  <td className="border-b text-sm text-center">
                    {detail.ordered_box_qty ? detail.ordered_box_qty : "-"}
                  </td>
                  <td className="border-b text-sm text-center">
                    {detail.ordered_qty}
                  </td>
                  <td className="border-b text-sm text-center">
                    € {parseFloat(detail.unit_amount).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="bg-gray-50">
              <tr>
                <td colSpan={4} className="text-xs font-medium text-right pt-4">
                  Sub-Total:
                </td>
                <td className="text-xs pt-4 text-right">
                  € {parseFloat(salesOrder.subtotal).toFixed(2)}
                </td>
              </tr>

              <tr>
                <td colSpan={4} className="text-xs font-medium text-right">
                  Tax:
                </td>
                <td className="text-xs text-right">
                  € {parseFloat(salesOrder.tax_amount).toFixed(2)}
                </td>
              </tr>

              <tr>
                <td colSpan={4} className="text-xs font-medium text-right">
                  Total:
                </td>
                <td className="text-xs text-right">
                  € {parseFloat(salesOrder.total_amount).toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="z-0">
      {loading && <LoadingView />}

      {salesOrderEl}
    </div>
  );
}

export default SalesOrderDetails;
