import React, { useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";

var timer = null;

function ProductSearch({ onSearch, onClear, defaultText }) {
  const [text, setText] = useState("");
  const [searchBegan, setSearchBegan] = useState(false);

  useEffect(() => {
    setText(defaultText);
    setSearchBegan(defaultText !== "");
  }, [defaultText]);

  const handleSearchTextUpdate = (e) => {
    const searchText = e.target.value;
    setText(searchText);

    clearTimeout(timer);

    if (searchText === "") {
      onClear();
      return;
    }

    timer = setTimeout(() => {
      setSearchBegan(true);
      onSearch(searchText);
    }, 1000);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    clearTimeout(timer);

    setSearchBegan(true);

    onSearch(text);
  };

  const handleClear = () => {
    setSearchBegan(false);
    setText("");

    onClear();
  };

  return (
    <div className="form-control flex">
      <form onSubmit={handleSearch}>
        <div className="input-group">
          <input
            type="text"
            placeholder="Search…"
            className="input-sm md:input-md input-bordered w-full border-gray-200 focus:border-gray-300"
            value={text}
            onChange={handleSearchTextUpdate}
          />

          <div
            onClick={handleClear}
            className="flex flex-col w-20 justify-center"
          >
            <button
              type="button"
              className={`btn btn-sm md:btn-md ${
                !searchBegan ? "btn-disabled" : ""
              }`}
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ProductSearch;
