import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/Auth/AuthContext";
import CartContext from "../../context/Cart/CartContext";
import NewArrivalProductListItem from "./NewArrivalProductListItem";

function NewArrivalProductList({ products }) {
  const { addProductToCart, cartSummary, cartBoxSummary } =
    useContext(CartContext);
  const { customerInfo: customer, getCustomerInfo } = useContext(AuthContext);

  useEffect(() => {
    if (!customer) {
      getCustomerInfo();
    }
  }, []);

  const addProductHandler = (product, quantity, boxQuantity) => {
    addProductToCart(product, quantity, boxQuantity);
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto my-4 w-full">
        <thead>
          <tr className="bg-gray-300">
            <th className="p-2 text-xs font-semibold text-left">Code</th>
            <th className="p-2 text-xs font-semibold text-left">Product</th>
            <th className="p-2 text-xs font-semibold text-center w-20">
              Qty/Box
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <NewArrivalProductListItem
              key={product.product_id}
              product={product}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default NewArrivalProductList;
