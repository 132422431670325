import React from "react";
import {
  CheckCircleIcon,
  XCircleIcon,
  AnnotationIcon,
  EyeIcon,
  DuplicateIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";

function SalesOrdersItem({ order, onDuplicateOrder }) {
  const statusIcon =
    order.salesorder_status === 1 ? (
      <CheckCircleIcon className="w-4 h-4 text-primary" />
    ) : (
      <XCircleIcon className="w-4 h-4 text-error" />
    );

  return (
    <tr className="h-12">
      <td className="p-2 border-b text-xs xl:text-sm">
        {new Date(order.create_date).toISOString().slice(0, 10)}
      </td>
      <td className="p-2 border-b text-xs xl:text-sm">
        <Link
          to={`/users/sales-orders/${order.salesorder_id}`}
          className="decoration-dotted underline"
          title="view details"
        >
          {order.salesorder_code}
        </Link>
      </td>

      <td className="p-2 border-b text-xs xl:text-sm">
        € {parseFloat(order.subtotal).toFixed(2)}
      </td>
      <td className="p-2 border-b text-xs xl:text-sm">
        € {parseFloat(order.tax_amount).toFixed(2)}
      </td>
      <td className="p-2 border-b text-xs xl:text-sm">
        € {parseFloat(order.total_amount).toFixed(2)}
      </td>
      <td className="p-2 border-b text-xs xl:text-sm w-10 text-center">
        {order.private_note ? (
          <div
            className="tooltip tooltip-left w-4 h-4 p-0 m-0"
            data-tip={order.private_note}
          >
            <button className="btn btn-ghost w-4 h-4 p-0 m-0 block">
              <AnnotationIcon className="w-4 h-4" />
            </button>
          </div>
        ) : null}
      </td>
      <td className="p-2 border-b text-xs xl:text-sm text-center w-8">
        <button>{statusIcon}</button>
      </td>
      <td className="p-2 border-b text-xs xl:text-sm text-center w-8">
        <Link
          to={`/users/sales-orders/${order.salesorder_id}`}
          title="view details"
        >
          <EyeIcon className="w-4 h-4" />
        </Link>
      </td>
      <td className="p-2 border-b text-xs xl:text-sm text-center w-8">
        <button
          onClick={onDuplicateOrder.bind(null, order.salesorder_id)}
          title="Duplicate order"
        >
          <DuplicateIcon className="w-4 h-4" />
        </button>
      </td>
    </tr>
  );
}

export default SalesOrdersItem;
