import React, { useContext, useEffect, useState } from "react";
import CartContext from "../../context/Cart/CartContext";
import { Link, useNavigate } from "react-router-dom";
import { ChevronRightIcon, ShoppingCartIcon } from "@heroicons/react/solid";
import ProductContext from "../../context/Product/ProductContext";
import { calculateProductSummary } from "../../helpers/Calculation";
import CartList from "../../components/Cart/CartList";
import LoadingView from "../../components/Common/LoadingView";
import AuthContext from "../../context/Auth/AuthContext";

function Cart() {
  const {
    loading,
    cartProducts,
    clearCart,
    createSalesOrder,
    salesOrderSuccess,
  } = useContext(CartContext);

  const { clearProducts, clearBarcodeProduct } = useContext(ProductContext);
  const { customerInfo: customer, getCustomerInfo } = useContext(AuthContext);

  const [orderNote, setOrderNote] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (!customer) {
      getCustomerInfo();
    }
  }, []);

  useEffect(() => {
    if (salesOrderSuccess) {
      clearBarcodeProduct();
      clearProducts();
      clearCart();

      navigate("/users");
    }
  }, [salesOrderSuccess]);

  const handleCheckout = () => {
    const { salesTax, subtotal, total } = calculateProductSummary(
      cartProducts,
      0
    );

    createSalesOrder(salesTax, subtotal, total, orderNote);
  };

  const handleContinueShopping = () => {
    clearBarcodeProduct();
    navigate("/users");
  };

  if (cartProducts.length === 0) {
    return (
      <div>
        <p>No items availabe in cart</p>

        <Link to="/users" className="btn btn-neutral btn-sm mt-8">
          Add Products
        </Link>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      {loading ? <LoadingView /> : null}

      <CartList items={cartProducts} showPrice={customer?.show_price} />

      <div className="w-4/5 m-auto mt-8 mb-4 ">
        <label className="block mb-2 text-sm">
          Enter message below. This will be added to your sales order.
        </label>
        <textarea
          onChange={(e) => setOrderNote(e.target.value)}
          value={orderNote}
          className="w-full border-gray-300"
        />
      </div>

      <div className="flex w-4/5 m-auto justify-end mt-6">
        <button
          className="btn btn-primary btn-sm gap-1 mr-2 text-white"
          onClick={handleContinueShopping}
        >
          Continue Shopping
          <ShoppingCartIcon className="w-4 h-4 " />
        </button>

        <button
          className={`btn btn-neutral btn-sm gap-1 ${
            loading ? "loading btn-disabled" : ""
          }`}
          disabled={loading}
          onClick={handleCheckout}
        >
          Checkout
          <ChevronRightIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}

export default Cart;
