import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import React from "react";
import App from "../App";
import Auth from "../Auth";
import Dashboard from "../pages/users/Dashboard";
import CategoryProduct from "../pages/users/CategoryProduct";
import Profile from "../pages/users/Profile";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import { CartProvider } from "../context/Cart/CartContext";
import Cart from "../pages/users/Cart";
import { ProductProvider } from "../context/Product/ProductContext";
import { AuthProvider } from "../context/Auth/AuthContext";
import Logout from "../pages/users/Logout";
import SalesOrder from "../pages/users/SalesOrder";
import SalesOrderDetails from "../pages/users/SalesOrderDetails";
import NewArrivals from "../pages/users/NewArrivals";
import { NewProductProvider } from "../context/RecentProduct/NewProductContext";
import UpdateProduct from "../pages/users/UpdateProduct";

function AppRoutes() {
  return (
    <AuthProvider>
      <ProductProvider>
        <NewProductProvider>
          <CartProvider>
            <Router>
              <Routes>
                <Route path="/" element={<App />}>
                  <Route index element={<Login />} />
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                </Route>

                <Route path="/users" element={<Auth />}>
                  <Route index element={<Dashboard />} />
                  <Route
                    path="category/:categoryId/products"
                    element={<CategoryProduct />}
                  />
                  <Route path="cart" element={<Cart />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="logout" element={<Logout />} />
                  <Route path="sales-orders" element={<SalesOrder />} />
                  <Route
                    path="sales-orders/:salesOrderId"
                    element={<SalesOrderDetails />}
                  />
                  <Route path="new-arrivals" element={<NewArrivals />} />
                  <Route
                    path="products/update/:productId"
                    element={<UpdateProduct />}
                  />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Router>
          </CartProvider>
        </NewProductProvider>
      </ProductProvider>
    </AuthProvider>
  );
}

export default AppRoutes;
