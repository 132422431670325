import React, { useContext, useEffect } from "react";
import NewProductContext from "../../context/RecentProduct/NewProductContext";
import LoadingView from "../../components/Common/LoadingView";
import { useNavigate } from "react-router-dom";
import { AuthorizationError } from "../../services/api";
import NewArrivalProductList from "../../components/NewArrival/NewArrivalProductList";

function NewArrivals() {
  const { currentNewProducts, fetchNewProducts, clearNewProducts } =
    useContext(NewProductContext);

  const navigate = useNavigate();

  useEffect(() => {
    clearNewProducts();

    fetchNewProducts().catch((error) => {
      if (error instanceof AuthorizationError) {
        navigate("/users/logout");
      } else {
        console.log(error.message);
      }
    });
  }, []);

  return (
    <div>
      {currentNewProducts.length > 0 ? (
        <>
          <NewArrivalProductList products={currentNewProducts} />
        </>
      ) : null}
    </div>
  );
}

export default NewArrivals;
