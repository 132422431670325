import { useContext, useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import AlertView from "./components/Common/AlertView";
import Navigation from "./components/layout/Navigation";
import AuthContext from "./context/Auth/AuthContext";
import CartContext from "./context/Cart/CartContext";

function Auth() {
  const { isLoggedIn } = useContext(AuthContext);
  const { salesOrderMessage, clearCartMessage } = useContext(CartContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (salesOrderMessage) {
      setTimeout(() => {
        clearCartMessage();
      }, 3000);
    }
  }, [salesOrderMessage]);

  return (
    <div className="min-h-full">
      <Navigation />

      <main className="mt-16">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <Outlet />
        </div>
      </main>

      {salesOrderMessage ? <AlertView message={salesOrderMessage} /> : null}
    </div>
  );
}

export default Auth;
