import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../context/Auth/AuthContext";

function Login() {
  const { isLoading, isLoggedIn, loginUser, error } = useContext(AuthContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/users");
    }
  }, [isLoggedIn]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (email !== "" && password !== "") {
      loginUser(email, password);
    } else {
      alert("Invalid email or password");
    }
  };

  return (
    <>
      <div className="relative flex flex-1 flex-col items-center justify-center pt-12 pb-16">
        <div className="max-w-md w-full space-y-8">
          <div className="w-full max-w-sm">
            <h2 className="mt-8 mb-8 text-center text-xl text-gray-600">
              Login
            </h2>
          </div>

          <form method="POST" onSubmit={submitHandler}>
            {error && (
              <div className="text-xs text-red-400 bg-red-100 p-2 rounded-lg mb-4">
                {error}
              </div>
            )}

            <div className="form-control mb-6">
              <label htmlFor="email" className="label">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="input input-bordered rounded-lg"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-control mb-6">
              <label htmlFor="password" className="label">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="input input-bordered rounded-lg"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mb-6 mt-4">
              <button
                type="submit"
                disabled={isLoading}
                className={`btn w-full rounded-lg ${
                  isLoading ? "loading btn-disabled" : ""
                }`}
              >
                Sign in
              </button>
            </div>

            <div className="text-center form-control mb-6">
              <NavLink
                to="/forgot-password"
                className="link link-hover mx-auto"
              >
                Forgot your password?
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
