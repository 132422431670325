import React from "react";
import ProductCategoryListItem from "./ProductCategoryListItem";

function ProductCategoryList({ categories, currentCategory }) {
  return (
    <div className="w-full md:w-60">
      <h3 className="text-md font-semibold mb-4 uppercase">Category</h3>
      <div className="text-sm mr-4 mb-4 lg:h-96 overflow-scroll">
        <ProductCategoryListItem to="/users" title="All" />

        {categories
          .filter((c) => c.desc !== "")
          .map((c) => (
            <ProductCategoryListItem
              to={`/users/category/${c.id}/products`}
              key={c.id}
              title={c.desc}
              active={c.id === currentCategory?.id}
            />
          ))}
      </div>
    </div>
  );
}

export default ProductCategoryList;
