export const calculateTaxableAmount = (price, quantity, discount) => {
  return (
    price * quantity - (discount ? (discount / 100) * price * quantity : 0)
  );
};

const computeTax = (prod, taxInclude) => {
  const { product, quantity } = prod;

  const total = salesPriceOrDefault(product.sales_price) * quantity;

  if (taxInclude) {
    let _subtotal =
      total / (1 + vatRateOrDefault(product.vat_on_sale?.rate) / 100);
    return total - _subtotal;
  } else {
    return (vatRateOrDefault(product.vat_on_sale?.rate) / 100) * total;
  }
};

export const calculateProductSummary = (products, discountRate) => {
  if (products.length == 0) {
    return { total: 0, salesTax: 0, subtotal: 0, discount: 0 };
  }

  const _total = products
    .map(
      ({ product: p, quantity }) =>
        salesPriceOrDefault(p.sales_price) * quantity
    )
    .reduce((prev, cur) => prev + cur);

  const _salesTax = products
    .map((p) => computeTax(p, false))
    .reduce((prev, cur) => prev + cur);

  const _subTotal = false ? _total - _salesTax : _total;

  const _discount = discountRate ? (discountRate / 100) * _subTotal : 0;

  return {
    salesTax: _salesTax.toFixed(2),
    subtotal: _subTotal.toFixed(2),
    discount: _discount.toFixed(2),
    total: (_subTotal + _salesTax - _discount).toFixed(2),
  };
};

const vatRateOrDefault = (rate) => {
  return rate === undefined || rate === null ? 0 : rate;
};

const salesPriceOrDefault = (price) => {
  return price === undefined || price === null ? 0 : price;
};

export const calculateTotalStock = (stocks) => {
  return stocks?.reduce(
    (prev, cur) => prev + (cur?.qty ? parseInt(cur?.qty) : 0),
    0
  );
};
