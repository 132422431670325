export const LOGIN_KEYS = {
  token: "token",
  refreshToken: "refresh-token",
  expiry: "expiry",
  loggedIn: "logged-in",
};

const USER_KEYS = {
  name: "name",
  email: "email",
  showOutStockProduct: "show-out-stock-product",
  isStoreUser: "is-store-user",
};

export const saveLogin = (token, refreshToken, expiry, loggedIn) => {
  localStorage.setItem(LOGIN_KEYS.token, token);
  localStorage.setItem(LOGIN_KEYS.refreshToken, refreshToken);
  localStorage.setItem(LOGIN_KEYS.expiry, expiry);
  localStorage.setItem(LOGIN_KEYS.loggedIn, loggedIn);
};

export const getToken = () => {
  return localStorage.getItem(LOGIN_KEYS.token);
};

export const getLogin = () => {
  return {
    token: localStorage.getItem(LOGIN_KEYS.token),
    refreshToken: localStorage.getItem(LOGIN_KEYS.refreshToken),
    expiry: localStorage.getItem(LOGIN_KEYS.expiry),
    loggedIn: localStorage.getItem(LOGIN_KEYS.loggedIn),
  };
};

export const clearLogin = () => {
  localStorage.clear();
};

export const saveUser = (name, email, isStoreUser) => {
  localStorage.setItem(USER_KEYS.name, name);
  localStorage.setItem(USER_KEYS.email, email);
  localStorage.setItem(USER_KEYS.isStoreUser, isStoreUser);
};

export const getUser = () => {
  return {
    name: localStorage.getItem(USER_KEYS.name),
    email: localStorage.getItem(USER_KEYS.email),
    isStoreUser: localStorage.getItem(USER_KEYS.isStoreUser),
  };
};

export const storeShowOutStockProduct = (show) => {
  localStorage.setItem(USER_KEYS.showOutStockProduct, show);
};

export const getShowOutStockProduct = () => {
  const show = localStorage.getItem(USER_KEYS.showOutStockProduct);

  return show ? show : false;
};
