import { createContext, useReducer } from "react";
import { today } from "../../helpers/Date";
import { getToken } from "../Auth/Storage";
import { postRequest } from "../../services/api";
import cartReducer from "./CartReducer";
import {
  getCartItems,
  getCartSummary,
  getCartBoxSummary,
  removeCartItems,
  removeCartSummary,
  saveCartItems,
  saveCartSummary,
  saveCartBoxSummary,
  removeCartBoxSummary,
} from "./CartStorage";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const initialState = {
    cartProducts: getCartItems(),
    cartSummary: getCartSummary(),
    cartBoxSummary: getCartBoxSummary(),
    salesOrderSuccess: null,
    salesOrderMessage: null,
    loading: false,
  };

  const [state, dispatch] = useReducer(cartReducer, initialState);

  const addProductToCart = (product, quantity, boxQuantity) => {
    const existingProducts = state.cartProducts;
    const existingSummary = state.cartSummary;
    const existingBoxSummary = state.cartBoxSummary;

    const index = existingProducts.findIndex(
      (_product) => _product.product.product_id === product.product_id
    );

    var items = [];

    if (index === -1) {
      if (quantity > 0) {
        items = [...existingProducts, { product, quantity, boxQuantity }];
      } else {
        items = [...existingProducts];
      }
    } else {
      if (quantity === 0) {
        items = existingProducts.filter(
          (item) => item.product.product_id !== product.product_id
        );
      } else {
        existingProducts[index].quantity = quantity;
        existingProducts[index].boxQuantity = boxQuantity;
        items = existingProducts;
      }
    }

    existingSummary[`${product.product_id}`] = quantity;
    existingBoxSummary[`${product.product_id}`] = boxQuantity;

    saveCartItems(items);
    saveCartSummary(existingSummary);
    saveCartBoxSummary(existingBoxSummary);

    dispatch({
      type: "ADD_ITEM_TO_CART",
      payload: {
        items,
        itemsSummary: existingSummary,
        itemsBoxSummary: existingBoxSummary,
      },
    });
  };

  const addProductsToCart = (list) => {
    var existingSummary = {};
    var existingBoxSummary = {};

    console.log(list);
    list.forEach((item) => {
      existingSummary[`${item.product.product_id}`] = item.quantity;
      existingBoxSummary[`${item.product.product_id}`] = item.boxQuantity;
    });

    saveCartItems(list);
    saveCartSummary(existingSummary);
    saveCartBoxSummary(existingBoxSummary);

    dispatch({
      type: "ADD_ITEM_TO_CART",
      payload: {
        items: list,
        itemsSummary: existingSummary,
        itemsBoxSummary: existingBoxSummary,
      },
    });
  };

  const clearCart = () => {
    removeCartItems();
    removeCartSummary();
    removeCartBoxSummary();

    dispatch({
      type: "CLEAR_CART",
    });
  };

  const clearCartMessage = () => {
    dispatch({
      type: "CLEAR_CART_MESSAGE",
    });
  };

  const createSalesOrder = async (tax, subtotal, total, orderNote) => {
    setLoading();

    const orderProducts = [];

    state.cartProducts.forEach((prod) => {
      const { product, quantity, boxQuantity } = prod;

      orderProducts.push({
        custom_price: false,
        hide: false,
        product_name: product.product_name,
        product_id: product.product_id,
        product_ordered_desc: product.product_desc ? product.product_desc : "",
        unit_amount: product.sales_price ? product.sales_price : 0,
        qty: quantity,
        sub_total: 0,
        inventory_location_desc: product.stocks?.location_desc
          ? product.stocks.location_desc
          : "",
        inventory_location_id: product.stocks?.location_id
          ? product.stocks.location_id
          : "",
        disc_percent: 0,
        apply_tax: 1,
        stock_amount: "-390.00",
        apply_tax_value: product.vat_on_sale?.rate,
        inventory_tracking: true,
        straight_line_sum: `${product.sales_price ? product.sales_price : 0}`,
        qty_per_box: product.qty_per_box,
        ordered_box_qty: boxQuantity,
      });
    });

    const salesOrder = {
      salesorder_status: 0,
      salesorder_date: today,
      delivery_date: today,
      tax_amount: tax,
      subtotal: subtotal,
      total_amount: total,
      customer_id: 1,
      address_id: 1,
      choices: orderProducts,
      documents: [],
      private_note: orderNote,
    };

    const response = await postRequest("sales-orders", salesOrder);

    const httpStatus = response.status;
    const { message } = response.json;

    var success = false;
    if (httpStatus >= 200 && httpStatus < 300) {
      success = true;
    }

    dispatch({
      type: "CREATE_SALES_ORDER",
      payload: {
        success,
        message,
      },
    });
  };

  const removeProduct = (product) => {
    const cartSummary = { ...state.cartSummary };
    const cartBoxSummary = { ...state.cartBoxSummary };

    const updatedCartProducts = [...state.cartProducts].filter(
      (p) => p.product.product_id !== product.product_id
    );
    delete cartSummary[`${product.product_id}`];
    delete cartBoxSummary[`${product.product_id}`];

    saveCartItems(updatedCartProducts);
    saveCartSummary(cartSummary);
    saveCartBoxSummary(cartBoxSummary);

    dispatch({
      type: "REMOVE_CART_ITEM",
      payload: {
        products: updatedCartProducts,
        summary: cartSummary,
        boxSummary: cartBoxSummary,
      },
    });
  };

  const setLoading = () => {
    dispatch({
      type: "SET_LOADING",
    });
  };

  return (
    <CartContext.Provider
      value={{
        cartProducts: state.cartProducts,
        cartSummary: state.cartSummary,
        cartBoxSummary: state.cartBoxSummary,
        salesOrderSuccess: state.salesOrderSuccess,
        salesOrderMessage: state.salesOrderMessage,
        loading: state.loading,
        addProductToCart,
        addProductsToCart,
        clearCart,
        createSalesOrder,
        removeProduct,
        clearCartMessage,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
