import React from "react";
import { NavLink } from "react-router-dom";

function ProductCategoryListItem({ to, title, active }) {
  return (
    <NavLink
      to={to}
      className={`inline-block md:block ${
        active
          ? "bg-gray-600 text-white"
          : "bg-gray-100 md:bg-transparent hover:bg-gray-300"
      } m-2 rounded-md md:rounded-none p-2 cursor-pointer text-xs xl:text-sm`}
    >
      {title}
    </NavLink>
  );
}

export default ProductCategoryListItem;
