import { createContext, useReducer } from "react";
import { getToken } from "../Auth/Storage";
import productReducer from "./ProductReducer";
import { getShowOutStockProduct } from "../Auth/Storage";
import { getRequest } from "../../services/api";

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const showOutOfStockProduct = getShowOutStockProduct();

  const initialState = {
    loading: false,
    categories: [],
    taxRates: [],
    allProducts: [],
    currentProducts: [],
    currentPage: 0,
    lastPage: 0,
    barcodeProduct: undefined,
    salesOrders: [],
    showOutOfStockProduct: showOutOfStockProduct,
  };

  const [state, dispatch] = useReducer(productReducer, initialState);

  const fetchProducts = async (page = 1, search = null) => {
    setLoading();

    var existingProducts = state.allProducts[page];

    if (existingProducts === undefined) {
      existingProducts = [];
    }

    if (search !== null && page === 1) {
      existingProducts = [];
    }

    if (existingProducts.length > 0) {
      dispatch({
        type: "LOAD_EXISTING_PRODUCTS",
        payload: {
          currentProducts: existingProducts,
          currentPage: page,
        },
      });
    } else {
      var path = `products?limit=25&page=${page}`;

      if (search) {
        var searchKey = "product_name";
        if (search.startsWith("NS-")) {
          searchKey = "product_code";
        }

        path = `${path}&filter[${searchKey}]=${search}`;
      }

      path = `${path}&visable_all=${state.showOutOfStockProduct ? "1" : "0"}`;

      try {
        const { data, current_page, last_page } = await getRequest(path);

        dispatch({
          type: "GET_PRODUCTS",
          payload: {
            currentProducts: data,
            currentPage: current_page,
            lastPage: last_page,
          },
        });
      } catch (error) {
        throw error;
      }
    }
  };

  // const searchProducts = async (search, page = 1) => {

  //   var existingProducts = state.allProducts[page];
  //   if(page === 1){
  //     clearProducts()
  //     existingProducts = []
  //   }

  // }

  const fetchCategories = async () => {
    const { data } = await getRequest("product-families?limit=100");

    dispatch({
      type: "GET_PRODUCT_CATEGORIES",
      payload: data.map((d) => {
        return {
          id: d.product_family_id,
          desc: d.product_family_desc,
        };
      }),
    });
  };

  const fetchTaxRates = async () => {
    const { data } = await getRequest("tax-rates?limit=25");

    dispatch({
      type: "GET_TAX_RATES",
      payload: data,
    });
  };

  const clearProducts = () => {
    dispatch({
      type: "CLEAR_PRODUCTS",
    });
  };

  const setLoading = () => {
    dispatch({
      type: "SET_LOADING",
    });
  };

  const fetchBarcodeProduct = async (code) => {
    setLoading();

    const data = await getRequest(`products?barcode=${code}`);

    const product = data.data[0];

    dispatch({
      type: "GET_BARCODE_PRODUCT",
      payload: product,
    });
  };

  const clearBarcodeProduct = () => {
    dispatch({
      type: "CLEAR_BARCODE_PRODUCT",
    });
  };

  const getSalesOrders = async () => {
    setLoading();

    const response = await getRequest("sales-orders?limit=100");

    dispatch({
      type: "GET_SALES_ORDERS",
      payload: {
        orders: response.data,
      },
    });
  };

  const saveShowOutStockProduct = (show) => {
    clearProducts();

    dispatch({
      type: "SAVE_SHOW_OUT_STOCK_PRODUCT",
      payload: show,
    });
  };

  const updateProductStock = (product, stock) => {
    var updatedProducts = [...state.currentProducts];
    var updatedAllProducts = [...state.allProducts];

    const index = updatedProducts.findIndex(
      (c) => c.product_id === product.product_id
    );
    updatedProducts[index].stocks[0].qty = stock;

    const index2 = updatedAllProducts[state.currentPage].findIndex(
      (a) => a.product_id === product.product_id
    );
    updatedAllProducts[state.currentPage][index2].stocks[0].qty = stock;

    dispatch({
      type: "UPDATE_CURRENT_PRODUCT",
      payload: {
        allProducts: updatedAllProducts,
        currentProducts: updatedProducts,
      },
    });
  };

  const getProduct = (id) => {};

  return (
    <ProductContext.Provider
      value={{
        categories: state.categories,
        currentProducts: state.currentProducts,
        currentPage: state.currentPage,
        lastPage: state.lastPage,
        taxRates: state.taxRates,
        loading: state.loading,
        barcodeProduct: state.barcodeProduct,
        salesOrders: state.salesOrders,
        showOutOfStockProduct: state.showOutOfStockProduct,
        fetchProducts,
        fetchCategories,
        clearProducts,
        fetchTaxRates,
        fetchBarcodeProduct,
        clearBarcodeProduct,
        getSalesOrders,
        saveShowOutStockProduct,
        getProduct,
        updateProductStock,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContext;
