import { createContext, useReducer } from "react";
import newProductReducer from "./NewProductReducer";
import { getRequest } from "../../services/api";

const NewProductContext = createContext();

export const NewProductProvider = ({ children }) => {
  const initialState = {
    loading: false,
    allProducts: [],
    currentProducts: [],
    currentPage: 0,
    lastPage: 0,
  };

  const [state, dispatch] = useReducer(newProductReducer, initialState);

  const fetchNewProducts = async (page = 1) => {
    setLoading();

    var existingProducts = state.allProducts[page];

    if (existingProducts === undefined) {
      existingProducts = [];
    }

    if (existingProducts.length > 0) {
      dispatch({
        type: "LOAD_NEW_PRODUCTS",
        payload: {
          currentProducts: existingProducts,
          currentPage: page,
        },
      });
    } else {
      var path = `products/-/new-arrivals?limit=25&page=${page}`;

      try {
        const { data, current_page, last_page } = await getRequest(path);

        dispatch({
          type: "GET_NEW_PRODUCTS",
          payload: {
            currentProducts: data,
            currentPage: current_page,
            lastPage: last_page,
          },
        });
      } catch (error) {
        throw error;
      }
    }
  };

  const clearNewProducts = () => {
    dispatch({
      type: "CLEAR_NEW_PRODUCTS",
    });
  };

  const setLoading = () => {
    dispatch({
      type: "SET_LOADING",
    });
  };

  return (
    <NewProductContext.Provider
      value={{
        currentNewProducts: state.currentProducts,
        currentPage: state.currentPage,
        lastPage: state.lastPage,
        loading: state.loading,
        fetchNewProducts,
        clearNewProducts,
      }}
    >
      {children}
    </NewProductContext.Provider>
  );
};

export default NewProductContext;
