import { storeShowOutStockProduct } from "../Auth/Storage";

const productReducer = (state, action) => {
  switch (action.type) {
    case "GET_PRODUCTS":
      const _allProducts = [...state.allProducts];
      _allProducts[action.payload.currentPage] = action.payload.currentProducts;

      return {
        ...state,
        allProducts: _allProducts,
        currentProducts: action.payload.currentProducts,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        loading: false,
      };
    case "LOAD_EXISTING_PRODUCTS":
      return {
        ...state,
        currentProducts: action.payload.currentProducts,
        currentPage: action.payload.currentPage,
        loading: false,
      };
    case "CLEAR_PRODUCTS":
      return {
        ...state,
        allProducts: [],
        currentProducts: [],
        currentPage: 0,
        lastPage: 0,
      };
    case "GET_PRODUCT_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
      };
    case "GET_TAX_RATES":
      return {
        ...state,
        taxRates: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "GET_BARCODE_PRODUCT":
      return {
        ...state,
        barcodeProduct: action.payload,
        loading: false,
      };
    case "CLEAR_BARCODE_PRODUCT":
      return {
        ...state,
        barcodeProduct: undefined,
      };
    case "GET_SALES_ORDERS":
      return {
        ...state,
        loading: false,
        salesOrders: action.payload.orders,
      };
    case "SAVE_SHOW_OUT_STOCK_PRODUCT":
      storeShowOutStockProduct(action.payload);
      return {
        ...state,
        showOutOfStockProduct: action.payload,
      };
    case "UPDATE_CURRENT_PRODUCT":
      return {
        ...state,
        allProducts: action.payload.allProducts,
        currentProducts: action.payload.currentProducts,
      };
    default:
      return state;
  }
};

export default productReducer;
