import React, { useEffect, useState } from "react";

var interval = null;

function LoadingView() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    startAnimating();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const startAnimating = () => {
    interval = window.setInterval(() => {
      setProgress((prev) => {
        var next = prev + 10;

        if (next > 100) {
          next = 0;
        }

        return next;
      });
    }, 300);
  };

  return (
    <div className="w-full fixed top-0 left-0 -mt-2">
      <progress
        className="progress progress-primary"
        value={progress}
        max="100"
      ></progress>
    </div>
  );
}

export default LoadingView;
