import React, { useContext, useEffect, useState } from "react";
import { PlusCircleIcon, MinusCircleIcon, XIcon } from "@heroicons/react/solid";
import { patchRequest } from "../../services/api";
import { calculateTotalStock } from "../../helpers/Calculation";
import ProductContext from "../../context/Product/ProductContext";

export default function UpdateStockForm({ product, customer, onDismissForm }) {
  const [productName, setProductName] = useState("");
  const [currentStock, setCurrentStock] = useState("");
  const [updatedStock, setUpdatedStock] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationIndex, setLocationIndex] = useState(0);

  const [error, setError] = useState([]);
  const [message, setMessage] = useState("");

  const { updateProductStock } = useContext(ProductContext);

  useEffect(() => {
    if (product) {
      setProductName(product.product_name);
      setCurrentStock(calculateTotalStock(product.stocks));
      setUpdatedStock(0);
      if (customer.location_id != null && customer.location_id != undefined) {
        setLocationIndex(customer.location_id);
      }
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    var params = {
      product_id: product.product_id,
      qty: updatedStock,
      location_id: product.stocks[locationIndex].location_id,
    };

    setIsSubmitting(true);

    const response = await patchRequest(
      `products/${product.product_id}/inventories`,
      params
    );
    const httpStatus = response.status;
    const { message } = response.json;

    var success = false;
    if (httpStatus >= 200 && httpStatus < 300) {
      success = true;
    }

    if (!success) {
      setMessage("");
      setError((_) => [message]);
    } else {
      setError([]);
      setMessage(message);

      setTimeout(() => {
        updateProductStock(
          product,
          parseInt(product.stocks[locationIndex].qty) + parseInt(updatedStock)
        );
        onDismissForm();
      }, 1000);
    }
    setIsSubmitting(false);
  };

  const addOneStock = () => {
    setUpdatedStock((prev) => prev + 1);
  };

  const deductOneStock = () => {
    setUpdatedStock((prev) => {
      return prev - 1;
    });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-sm">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
              <h2 className="my-2 text-xl text-gray-600">Update Inventory</h2>
              <button
                className="btn btn-sm btn-circle btn-ghost text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={onDismissForm}
              >
                <XIcon className="w-4 h-4 text-gray-500" />
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form method="POST" onSubmit={submitHandler}>
                {error.length > 0 && (
                  <div className="text-xs text-red-400 bg-red-100 p-2 rounded-lg mb-4">
                    <ul>
                      {error.map((err, idx) => (
                        <li key={idx}>{err}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {message !== "" && (
                  <div className="text-xs text-blue-500 bg-blue-100 p-2 rounded-lg mb-4 py-4 px-4">
                    {message}
                    <button
                      type="button"
                      className="float-right"
                      onClick={() => setMessage("")}
                    >
                      X
                    </button>
                  </div>
                )}

                <div className="form-control mb-2 flex-row">
                  <label htmlFor="product-name" className="label mr-4">
                    Product Name
                  </label>
                  <input
                    id="product-name"
                    name="product-name"
                    type="text"
                    className="input input-bordered rounded-lg disabled:bg-gray-100"
                    placeholder="Product Name"
                    value={productName}
                    disabled={"disabled"}
                  />
                </div>
                <div className="form-control mb-2 flex-row">
                  <label htmlFor="current-stock" className="label mr-4">
                    Current Stock
                  </label>
                  <input
                    id="current-stock"
                    name="current-stock"
                    type="text"
                    className="input input-bordered rounded-lg disabled:bg-gray-100"
                    placeholder="Current Stock"
                    value={currentStock}
                    disabled={"disabled"}
                  />
                </div>
                <div className="form-control mb-0 flex-row">
                  <label htmlFor="updated-stock" className="label mr-4">
                    Stock Change
                  </label>
                  <input
                    id="updated-stock"
                    name="updated-stock"
                    type="text"
                    className="input input-bordered rounded-lg"
                    placeholder="Updated Stock"
                    value={updatedStock}
                    onChange={(e) => setUpdatedStock(e.target.value)}
                  />
                </div>
                <div className="form-control mb-2 flex-row justify-end">
                  <button
                    type="button"
                    className="btn btn-sm rounded-full w-10 h-10 mx-2"
                    title="deduct"
                    onClick={deductOneStock}
                  >
                    <MinusCircleIcon className="w-4 h-4" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm rounded-full w-10 h-10"
                    title="add"
                    onClick={addOneStock}
                  >
                    <PlusCircleIcon className="w-4 h-4" />
                  </button>
                </div>
                <div className="form-control mb-0 mt-6 flex-row">
                  <label htmlFor="new-stock" className="label mr-4">
                    New Stock
                  </label>
                  <input
                    id="new-stock"
                    name="new-stock"
                    type="text"
                    className="input input-bordered rounded-lg"
                    placeholder="New Stock"
                    value={`${parseInt(currentStock) + parseInt(updatedStock)}`}
                    disabled={"disabled"}
                  />
                </div>
                <hr className="mt-6" />
                <div className="mb-4 mt-4 flex justify-center">
                  <button
                    type="submit"
                    className={`btn w-auto rounded-lg px-8 ${
                      isSubmitting ? "loading btn-disabled" : ""
                    }`}
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
