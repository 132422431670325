import React from "react";
import PaginationButton from "./PaginationButton";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";

const PREV = -10;
const NEXT = -20;

function Pagination({ active, pages, onPaginate }) {
  var paginationElement = [];

  const handlePagination = (item) => {
    if (item === active) {
      return;
    }

    var _index = item;
    if (item === PREV) {
      _index = active - 1;
    } else if (item === NEXT) {
      _index = active + 1;
    }

    onPaginate(_index);
  };

  if (pages <= 6) {
    for (var j = 1; j <= pages; j++) {
      paginationElement.push(
        <PaginationButton
          key={j}
          active={active === j}
          onClick={handlePagination.bind(null, j)}
        >
          {j}
        </PaginationButton>
      );
    }
  } else {
    var activePages = [1];
    for (var i = 2; i < pages; i++) {
      if (i === active) {
        activePages.push(i);
      }

      if (i === active - 1) {
        activePages.push(PREV);
      }

      if (i === active + 1 && i <= pages - 1) {
        activePages.push(NEXT);
      }
    }

    if (activePages[-1] !== pages) {
      activePages.push(pages);
    }

    activePages.forEach((item) => {
      paginationElement.push(
        <PaginationButton
          key={item}
          active={active === item}
          onClick={handlePagination.bind(null, item)}
        >
          {item > 0 ? (
            item
          ) : item === PREV ? (
            <ArrowLeftIcon className="w-4 h-4" />
          ) : (
            <ArrowRightIcon className="w-4 h-4" />
          )}
        </PaginationButton>
      );
    });
  }

  return <div className="btn-group">{paginationElement}</div>;
}

export default Pagination;
