import React, { useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/solid";

var timer = null;

function BarcodeProductSearch({ onSearch, onClear, defaultText }) {
  const [barcode, setBarcode] = useState("");
  const [searchBegan, setSearchBegan] = useState(false);

  useEffect(() => {
    setBarcode(defaultText);
    setSearchBegan(defaultText !== "");
  }, [defaultText]);

  const handleBarcodeUpdate = (e) => {
    let code = e.target.value;

    setBarcode(code);

    clearTimeout(timer);

    if (code === "") {
      // clear barcode search if any
      setSearchBegan(false);

      onClear();

      return;
    }

    timer = window.setTimeout(() => {
      setSearchBegan(true);
      onSearch(code);
    }, 1000);
  };

  const handleBarcodeSearch = (e) => {
    e.preventDefault();
    clearTimeout(timer);
    setSearchBegan(true);
    onSearch(barcode);
  };

  const handleBarcodeClear = () => {
    setBarcode("");
    onClear();
  };

  return (
    <div className="form-control flex justify-end">
      <form onSubmit={handleBarcodeSearch}>
        <div className="input-group">
          <input
            type="text"
            value={barcode}
            placeholder="Enter barcode here..."
            onChange={handleBarcodeUpdate}
            className="input-sm md:input-md border-gray-200 w-full"
          />

          <button
            type="button"
            className={`btn btn-sm md:btn-md w-20 btn-square ${
              !searchBegan ? "btn-disabled" : ""
            }`}
            onClick={handleBarcodeClear}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
}

export default BarcodeProductSearch;
