import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/Auth/AuthContext";
import CartContext from "../../context/Cart/CartContext";

function Logout() {
  const { logoutUser } = useContext(AuthContext);
  const { clearCart } = useContext(CartContext);

  useEffect(() => {
    clearCart();
    logoutUser();
  }, []);

  return <div>Logging out...</div>;
}

export default Logout;
